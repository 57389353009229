/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    a: "a",
    h2: "h2",
    div: "div",
    blockquote: "blockquote",
    h3: "h3",
    ol: "ol",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Integrations shouldn't be so hard."), "\n", React.createElement(_components.p, null, "That's the belief that led us to start Prismatic, and I can think of no better way to introduce Prismatic than that phrase. In this post, I'll address why this matters, why we care so much, and what we're doing about it. (And what you can do about it!)"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "This is the first post in a three-part series. Next, CTO Justin Hipple will examine what makes integrations so hard for engineering teams. (Read that post ", React.createElement(_components.a, {
    href: "/blog/integrations-shouldnt-be-so-hard-an-engineers-perspective/"
  }, "here"), ".) Then, we'll look at integration challenges across the other teams in a software company.")), "\n", React.createElement(_components.h2, {
    id: "a-bit-of-background-on-integrations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-bit-of-background-on-integrations",
    "aria-label": "a bit of background on integrations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A bit of background on integrations."), "\n", React.createElement(_components.p, null, "Integrations are everywhere, because software is everywhere. End users want all their different systems to work smoothly together, so software companies build integrations to connect their software to the other systems their customers use."), "\n", React.createElement(_components.p, null, "Integrations are good for users. They automate repetitive tasks, provide easier access to data, save time so users can focus on what matters, and can flat out help people do better work with less effort."), "\n", React.createElement(_components.p, null, "Unfortunately, integrations are also hard. At most software companies, especially those providing B2B software, the process of building, deploying, and supporting integrations is inefficient, time-consuming, and all-around painful for everyone involved. It's also ineffective, frequently resulting in integrations that fall short of customer needs."), "\n", React.createElement(_components.h2, {
    id: "why-does-this-matter",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-does-this-matter",
    "aria-label": "why does this matter permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why does this matter?"), "\n", React.createElement(_components.p, null, "Does it even matter that integrations are hard, that current integration processes are inefficient and ineffective?"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "It matters a lot, and here's why. Software has the potential to make huge impacts for the people who use it, but a lot of that potential goes unrealized, and the challenges surrounding integrations are a major culprit."), "\n"), "\n", React.createElement(_components.p, null, "There are two key ways in which integration challenges contribute to software products' unrealized potential impacts."), "\n", React.createElement(_components.h3, {
    id: "first-integrations-themselves-fall-far-short-of-their-potential-impact",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#first-integrations-themselves-fall-far-short-of-their-potential-impact",
    "aria-label": "first integrations themselves fall far short of their potential impact permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "First, integrations themselves fall far short of their potential impact."), "\n", React.createElement(_components.p, null, "Integrations themselves can be a major part of a software system's usefulness and can even make the difference between a system that saves time and one that does little more than add workload. But there are so many missed opportunities here. Customers wait months or years for integrations to be built. The resulting integrations are often lacking in functionality and reliability, don't produce the anticipated benefits, and can't be easily updated as needs evolve. Sometimes highly beneficial integrations are simply never built due to excessive time and expense."), "\n", React.createElement(_components.h3, {
    id: "second-inefficient-integration-work-significantly-detracts-from-core-product-innovation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#second-inefficient-integration-work-significantly-detracts-from-core-product-innovation",
    "aria-label": "second inefficient integration work significantly detracts from core product innovation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Second, inefficient integration work significantly detracts from core product innovation."), "\n", React.createElement(_components.p, null, "A software company's ability to positively impact end users – and continue doing so year after year – hinges largely on its ability to drive core product innovation. Integration work, when performed inefficiently, consumes an inordinate amount of development time and significantly reduces time spent building the kinds of new products and features that truly move the needle for customers."), "\n", React.createElement(_components.h2, {
    id: "why-do-we-care-so-much",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-do-we-care-so-much",
    "aria-label": "why do we care so much permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why do we care so much?"), "\n", React.createElement(_components.p, null, "We're passionate about this idea that software done right, and integrations done right, can have a real impact for end users and even the people end users serve. We saw this in very visceral ways at our previous company, where our software – and its integrations – did things like get emergency responders to 911 calls faster."), "\n", React.createElement(_components.p, null, "There are B2B software companies everywhere building products with the potential to make huge impacts, and anything that can be done to unlock more of that potential is an endeavor that should be pursued aggressively. We aim to do so by helping software teams build better, quicker integrations and spend more time driving core product innovation."), "\n", React.createElement(_components.p, null, "Together, my cofounders and I spent the last fifteen years building and scaling a software company with hundreds of integrations. We've lived the challenges of integrations from all sides – as founders, software engineers, engineering managers, product owners, customer escalation points, sales leaders, and executives. Integrations were hard when we had a couple customers and a couple integrations, and they were hard when we had thousands of customers with hundreds of unique integrations."), "\n", React.createElement(_components.p, null, "(Have I mentioned integrations are hard? They really, really are.)"), "\n", React.createElement(_components.h2, {
    id: "what-are-we-doing-about-it",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-we-doing-about-it",
    "aria-label": "what are we doing about it permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are we doing about it?"), "\n", React.createElement(_components.p, null, "We're building Prismatic."), "\n", React.createElement(_components.p, null, "Prismatic is an integration platform that makes it easier for software companies to provide integrations to their customers. It gives software teams the freedom to build exactly the integrations customers need while reducing the amount of time they spend on all the integration blocking and tackling."), "\n", React.createElement(_components.p, null, "Prismatic provides a purpose-built environment for building, deploying, and managing integrations, with a comprehensive toolset to handle integration requirements such as complex business logic, testing, managed deployment, and an embeddable white-labeled UI."), "\n", React.createElement(_components.p, null, "Because most integration scenarios are far from standard, Prismatic helps teams tackle specialized, non-standard, and vertical-specific integrations between applications of all kinds, SaaS or legacy, with or without a modern API, regardless of protocol or data format."), "\n", React.createElement(_components.p, null, "Development is underway, and we're excited to show you more about what we're building. Stay tuned!"), "\n", React.createElement(_components.h2, {
    id: "what-can-you-do-about-it",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-can-you-do-about-it",
    "aria-label": "what can you do about it permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What can you do about it?"), "\n", React.createElement(_components.p, null, "If, like us, you're excited about the prospect of better, quicker integrations and more time for core product innovation, we'd love your help."), "\n", React.createElement(_components.p, null, "The biggest thing you can do is help us get more perspectives and data points. The more conversations we have about the challenges of providing software integrations to customers, the better we can address them. Please take a few minutes to help us out however you can:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Help us reach a broader audience."), " Follow us on ", React.createElement(_components.a, {
    href: "https://twitter.com/prismatic_io",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Twitter"), ", ", React.createElement(_components.a, {
    href: "https://www.linkedin.com/company/prismatic-io",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "LinkedIn"), ", and ", React.createElement(_components.a, {
    href: "https://www.facebook.com/prismatic.io",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Facebook"), ", and share our posts!"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If you've worked on providing integrations to customers at a B2B software company:"), " Email us at ", React.createElement(_components.a, {
    href: "mailto:feedback@prismatic.io",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "feedback@prismatic.io"), " to tell us about it. What is/was your role, and what are the biggest integration headaches? We'd really love to hear from you."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "If you have colleagues or connections who've worked on providing integrations to customers:"), " Share this blog post with them and encourage them to email us at ", React.createElement(_components.a, {
    href: "mailto:feedback@prismatic.io",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "feedback@prismatic.io"), " to tell us about their experiences."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Like this post? This is the first in a three-part series. You can read Part 2 ", React.createElement(_components.a, {
    href: "/blog/integrations-shouldnt-be-so-hard-an-engineers-perspective/"
  }, "here"), ".")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
